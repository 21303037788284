<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else class="content">
      <template>
        <v-card
          :class="{ 'card-success': cadastro, 'card-warn': !cadastro }"
          style="min-height: 60vh"
        >
          <h4 v-if="!cadastro" slot="header" class="card-title text-center">
            Edição de Fazendas
          </h4>
          <h4 v-else slot="header" class="card-title text-center">
            Cadastro de Produtores
          </h4>
          <div class="clearfix" />
          <div>
            <v-form
              id="form"
              ref="form"
              v-model="valid"
              enctype="multipart/form-data"
              class="pa-3"
              lazy-validation
              @submit.prevent="action"
            >
              <v-card>
                <v-list-item-content>
                  <v-card-title class="headline mx-auto">
                    Dados do Produtor
                  </v-card-title>
                  <v-row v-if="!loading" class="pa-3">
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.name"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        :rules="requiredRules"
                        item-text="text"
                        label="Nome *"
                        required
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.email"
                        name="email"
                        label="Email *"
                        :rules="emailRules"
                        :disabled="!cadastro ? false : user"
                        required
                      />
                    </v-col>
                    <v-col cols="6" sm="3">
                      <v-text-field
                        v-model="form.cpf"
                        v-mask="maskCpf"
                        name="cpf"
                        label="CPF *"
                        :rules="cpfRules"
                        required
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-menu
                        v-model="selectDate"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="moment(form.birthday).format('DD/MM/YYYY')"
                            label="Data de Nascimento *"
                            prepend-icon="mdi-calendar"
                            color="teal"
                            readonly
                            :rules="requiredRules"
                            required
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="form.birthday"
                          style="margin: 0px"
                          color="blue"
                          no-title
                          :max="maxDate.toISOString().substr(0, 10)"
                          min="1900-01-01"
                          locale="pt-br"
                          @input="selectDate = false"
                        />
                      </v-menu>
                    </v-col>
                    <v-col cols="3">
                      <v-autocomplete
                        v-model="form.sexo"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        :rules="requiredRules"
                        :items="sexos"
                        item-text="text"
                        item-value="value"
                        label="Sexo *"
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.inscricao_rural"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        :rules="requiredRules"
                        item-text="text"
                        label="Inscrição Rural *"
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.matricula"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        :rules="requiredRules"
                        item-text="text"
                        label="Matrícula *"
                        required
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="form.telefone"
                        v-mask="maskTelCel"
                        :rules="[...requiredRules, ...telRules]"
                        label="Telefone Celular *"
                        type="tel"
                        required
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="form.telefone_residencial"
                        v-mask="maskTelRes"
                        :rules="[...telRules]"
                        label="Tel. Residencial"
                        type="tel"
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="form.telefone_comercial"
                        v-mask="maskTelRes"
                        :rules="[...telRules]"
                        label="Telefone Comercial"
                        type="tel"
                      />
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-card>
              <v-card>
                <v-list-item-content>
                  <v-card-title class="headline mx-auto">
                    Endereço
                  </v-card-title>
                  <v-row v-if="!loading" class="pa-3">
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="form.estado"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        label="Estado *"
                        :items="estado"
                        :rules="requiredRules"
                        item-text="Nome"
                        item-value="CodEstado"
                        :loading="!loadingData.estado"
                        :disabled="!loadingData.estado"
                        required
                        @change="changeCidade"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-autocomplete
                        v-model="form.cidade"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        label="Cidade *"
                        :items="cidade"
                        :rules="requiredRules"
                        item-text="Nome"
                        item-value="CodCidade"
                        :loading="!loadingData.cidade"
                        :disabled="!loadingData.cidade"
                        required
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="form.logradouro"
                        label="Logradouro *"
                        :rules="requiredRules"
                        required
                      />
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        v-model="form.numero"
                        label="Numero *"
                        :rules="requiredRules"
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.bairro"
                        label="Bairro *"
                        :rules="requiredRules"
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.complemento"
                        label="Complemento *"
                        :rules="requiredRules"
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.cep"
                        v-mask="maskCep"
                        type="text"
                        :rules="cepRules"
                        label="CEP *"
                        required
                      />
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-card>
              <v-btn
                type="submit"
                :disabled="salvando"
                :loading="salvando"
                :color="!$route.query.id ? 'success' : 'warning'"
                form="form"
              >
                {{ btnNome }}
              </v-btn>
              <router-link
                :disabled="salvando"
                :to="`/${$user.get().role}/produtores`"
              >
                <v-btn :disabled="salvando" color="error" class="ml-3">
                  Cancelar
                </v-btn>
              </router-link>
            </v-form>
          </div>
        </v-card>
      </template>
    </div>
  </v-card>
</template>
<script>
import { validaCPF } from '../../utils/validacoes'

export default {
  data() {
    return {
      activePicker: null,
      maxDate: new Date(),
      selectDate: false,
      edit: false,
      salvando: false,
      loadingData: {
        estado: false,
        cidade: false,
      },
      loading: false,
      maskCpf: '###.###.###-##',
      maskTelRes: '(##) ####-####',
      maskTelCel: '(##) # ####-####',
      maskCep: '##.###-###',
      cepRules: [
        v => !!v || 'CEP é obrigatório',
        v => v?.length >= 10 || 'CEP inválido',
      ],
      requiredRules: [v => !!v || '* Obrigatório'],
      emailRules: [v => /.+@.+/.test(v) || 'E-mail invalido'],
      telRules: [
        v => !v || v.length === 0 || v.length >= 10 || '* Número inválido',
      ],
      cpfRules: [
        v => !!v || '* Obrigatório',
        v => validaCPF(v) || '* CPF inválido',
      ],
      valid: null,
      btnNome: !this.$route.query.id ? 'Salvar' : 'Salvar Alterações',
      sexos: [
        {
          text: 'Feminino',
          value: 'Feminino',
        },
        {
          text: 'Masculino',
          value: 'Masculino',
        },
      ],
      form: {
        name: '',
        email: '',
        cpf: '',
        birthday: '2000-01-01',
        sexo: '',
        matricula: '',
        inscricao_rural: '',
        telefone_residencial: '',
        telefone: '',
        telefone_comercial: '',

        estado: 0,
        cidade: 0,
        bairro: '',
        logradouro: '',
        numero: '',
        complemento: '',
        cep: '',
      },

      user: '',

      estado: [{ Nome: 'Carregando', CodEstado: 0 }],
      cidade: [],
      cadastro: this.$route.query.id,
    }
  },

  created() {
    if (!this.$route.query.id) {
      this.loadNewForm()
    } else {
      this.form.cadastro = this.$route.query.id
      this.loadOldForm()
    }

    const d = new Date()
    this.maxDate = new Date(
      `${d.getFullYear() - 18}-${d.getMonth() + 1}-${d.getDate()}`,
    )
  },

  methods: {
    getForm() {
      const data = {
        name: this.form.name,
        email: this.form.email,
        cpf: this.form.cpf,
        sexo: this.form.sexo,
        birthday: this.form.birthday,
        matriculas: this.form.matricula,
        inscricao_rural: this.form.inscricao_rural,
        telefone: this.form.telefone,
        telefone_residencial: this.form.telefone_residencial,
        telefone_comercial: this.form.telefone_comercial,

        estado: this.form.estado,
        cidade: this.form.cidade,
        bairro: this.form.bairro,
        logradouro: this.form.logradouro,
        numero: this.form.numero,
        complemento: this.form.complemento,
        cep: this.form.cep,
      }

      return data
    },

    action() {
      this.salvando = !this.salvando

      this.valid = this.$refs.form.validate()

      if (!this.valid) {
        this.salvando = !this.salvando
        this.Swal.fire(
          'Atenção',
          'Preencha os itens obrigatórios sinalizados com ("*").',
          'warning',
        )
        return
      }

      if (!this.cadastro) {
        this.create()
      } else {
        this.update()
      }
    },

    update() {
      const data = this.getForm()

      try {
        this.api.update.produtor(this.cadastro, data).then(() => {
          this.Swal.fire({
            title: 'Sucesso',
            text: 'Produtor atualizado com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
          })
            .then(() => {
              this.$router.push({
                path: `/${this.$user.get().role}/produtores`,
              })
              this.salvando = !this.salvando
            })
            .catch(() => {
              this.salvando = !this.salvando
            })
        })
      } catch (error) {
        this.salvando = !this.salvando
        this.Swal.fire({
          title: 'Atenção',
          text: 'Ocorreu um erro ao atualizar o produtor, tente novamente!.',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    },

    async loadOldForm() {
      this.loading = true

      try {
        this.api.get.produtor(this.cadastro).then(res => {
          this.user = !!res?.user
          this.form.name = res.pessoafisica?.Nome
          this.form.email = res?.pessoa?.email
          this.form.cpf = res?.pessoafisica?.Cpf
          this.form.birthday = res?.pessoafisica?.DataNascimento ?? '2000-01-01'
          this.form.sexo = res?.pessoafisica?.sexo
          this.form.matricula = res?.Matricula
          this.form.inscricao_rural = res?.InscricaoRural
          this.form.telefone_residencial = res?.pessoa?.TelRes
          this.form.telefone = res?.pessoa?.TelCel
          this.form.telefone_comercial = res?.pessoa?.TelRec

          this.form.cidade = res.pessoa?.endereco?.cidade?.CodCidade
          this.form.estado = res.pessoa?.endereco?.cidade?.estado?.CodEstado
          this.form.bairro = res.pessoa?.endereco?.Bairro
          this.form.logradouro = res.pessoa?.endereco?.Logradouro
          this.form.numero = res.pessoa?.endereco?.Numero
          this.form.complemento = res.pessoa?.endereco?.Complemento
          this.form.cep = res.pessoa?.endereco?.Cep

          this.loadEstado()
          this.loadCidade(res?.pessoa?.endereco?.cidade?.CodEstado)
          this.loading = false
        })
      } catch (error) {
        this.Swal.fire({
          title: 'Atenção',
          text: 'Ocorreu um erro ao carregar o produtor.',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    },

    create() {
      const data = this.getForm()
      try {
        this.api.save
          .produtor(data)
          .then(res => {
            this.Swal.fire({
              title: 'Sucesso',
              text: res,
              icon: 'success',
              showConfirmButton: false,
              timer: 2000,
            }).then(() => {
              this.$router.push({
                path: `/${this.$user.get().role}/produtores`,
              })
            })
          })
          .catch(() => {
            this.salvando = !this.salvando
          })
      } catch (error) {
        this.salvando = false
        this.Swal.fire({
          title: 'Atenção',
          text: 'Ocorreu um erro ao salvar o produtor, tente novamente!',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      }
    },

    loadNewForm() {
      this.loadEstado()
      this.loading = false
    },

    loadEstado() {
      this.loadingData.estado = false
      this.api.get.estados().then(data => {
        this.estado = data
        this.loadingData.estado = true
      })
    },

    loadCidade(CodEstado) {
      this.loadingData.cidade = false
      this.api.get.cidades(CodEstado).then(data => {
        this.cidade = data
        this.loadingData.cidade = true
      })
    },

    changeCidade() {
      this.loadCidade(this.form.estado)
      this.form.cidade = ''
    },
  },
}
</script>
<style>
html {
  overflow: auto !important;
}

input[type='file'] {
  display: none;
}

#foto {
  background-color: #3498db;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  padding: 6px 20px;
}

.index {
  z-index: 1;
}
</style>
